import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 827.000000 827.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,827.000000) scale(0.100000,-0.100000)">
<path d="M3655 7644 c-71 -7 -170 -20 -220 -29 -49 -9 -110 -20 -135 -24 -43
-8 -43 -8 -10 -9 19 -1 53 3 75 8 150 36 601 59 790 40 66 -6 161 -15 210 -20
50 -5 99 -11 110 -13 11 -3 40 -8 65 -12 25 -4 79 -15 120 -26 41 -10 115 -27
164 -39 95 -23 283 -82 331 -104 17 -8 57 -24 90 -37 33 -12 69 -29 80 -37
l20 -14 -20 5 c-11 3 -65 22 -120 42 -213 76 -478 137 -715 165 -41 5 -166 15
-277 21 -199 12 -453 5 -703 -20 -135 -14 -395 -66 -530 -107 -259 -78 -389
-130 -590 -233 -338 -172 -603 -362 -883 -631 -208 -199 -471 -556 -620 -843
-56 -107 -76 -119 -27 -16 35 72 34 72 80 154 18 33 42 76 53 95 10 19 25 42
33 51 8 8 14 19 14 23 0 5 35 58 77 120 42 61 69 105 61 98 -46 -37 -301 -444
-365 -582 -219 -468 -343 -1005 -343 -1480 0 -522 111 -1024 344 -1549 79
-181 281 -518 408 -682 358 -466 752 -797 1243 -1046 292 -148 701 -284 1000
-333 84 -14 326 -30 431 -29 l99 2 -120 8 c-122 9 -313 33 -395 50 -25 5 -90
18 -145 29 -55 11 -125 27 -155 36 -30 9 -68 18 -85 21 -16 3 -34 9 -40 13 -5
4 -26 11 -45 15 -19 4 -43 12 -55 18 -47 24 -56 27 -72 27 -12 0 -14 3 -6 11
7 7 17 8 25 3 7 -5 36 -14 63 -21 28 -6 68 -16 90 -23 470 -133 1143 -156
1665 -55 122 23 368 89 450 120 19 8 82 31 140 53 588 222 1140 636 1513 1136
73 96 181 257 213 316 10 19 22 37 27 38 4 2 7 9 7 15 0 7 14 32 31 57 21 31
28 37 23 20 -3 -14 -14 -36 -24 -50 -9 -14 -23 -38 -32 -55 -33 -67 -85 -156
-150 -255 -38 -58 -55 -89 -38 -70 29 33 63 84 166 245 52 81 195 369 249 498
53 129 139 389 163 492 50 211 91 557 92 765 0 108 -22 399 -40 531 -58 419
-217 867 -468 1319 -86 155 -293 444 -438 612 -246 284 -676 614 -1041 796
-250 126 -638 269 -843 311 -226 46 -323 62 -450 71 -190 13 -453 11 -615 -6z
m697 -160 c78 -8 145 -17 148 -19 3 -2 42 -8 87 -15 262 -35 615 -154 913
-307 227 -116 418 -241 620 -406 116 -95 383 -368 490 -502 187 -235 364 -534
500 -847 18 -43 35 -76 37 -75 14 15 -182 438 -260 560 -20 31 -37 60 -37 63
0 5 -42 69 -101 155 -13 19 -40 55 -60 80 -19 24 -62 78 -94 119 -64 80 -217
253 -250 282 -12 11 -15 17 -7 18 18 0 166 -145 281 -275 224 -254 408 -531
524 -790 42 -95 138 -359 157 -435 99 -394 124 -585 124 -945 0 -276 -22 -501
-68 -700 -3 -11 -12 -54 -21 -95 -72 -324 -218 -684 -402 -995 -47 -77 -61
-74 -18 5 66 121 189 378 205 430 5 14 18 50 30 80 26 67 41 113 37 117 -2 1
-27 -57 -56 -130 -112 -277 -184 -416 -343 -658 -100 -150 -170 -241 -300
-385 -90 -100 -292 -294 -363 -349 -373 -287 -606 -425 -948 -559 -202 -79
-466 -151 -667 -181 -258 -38 -719 -44 -926 -10 -437 71 -694 150 -1024 315
-357 178 -650 384 -873 614 -272 281 -515 595 -620 801 -16 33 -32 55 -34 48
-8 -24 211 -383 306 -500 14 -17 44 -55 66 -84 53 -69 117 -140 247 -274 58
-60 105 -112 103 -114 -9 -8 -106 79 -255 228 -228 229 -418 488 -552 751
-129 254 -237 559 -287 810 -6 30 -16 78 -22 105 -19 92 -39 268 -51 444 -14
221 5 585 42 769 5 28 15 78 21 110 16 81 37 168 55 232 24 85 65 213 74 235
5 11 18 46 30 79 11 32 29 77 39 100 10 22 27 61 37 86 26 60 130 264 160 311
13 21 24 43 24 49 0 5 3 10 8 10 4 0 13 13 20 29 14 31 45 71 56 71 4 0 -5
-17 -19 -37 -14 -21 -25 -43 -25 -50 0 -7 -3 -13 -8 -13 -4 0 -13 -15 -21 -32
-7 -18 -23 -49 -36 -68 -12 -19 -41 -78 -64 -130 -24 -52 -49 -108 -56 -125
-27 -60 -45 -108 -45 -119 1 -6 15 23 32 64 59 139 198 399 292 542 111 170
183 263 340 438 148 166 458 423 656 546 231 144 380 221 585 302 336 133 515
177 950 231 120 15 464 12 617 -5z m-2036 -407 c-405 -250 -757 -557 -1046
-913 -55 -67 -202 -285 -279 -414 -53 -88 -175 -344 -229 -480 -72 -183 -156
-479 -183 -640 -24 -146 -49 -363 -50 -435 0 -44 -5 -89 -10 -100 -15 -31 -2
396 15 505 30 188 36 226 45 260 5 19 14 53 19 75 20 78 95 310 103 320 5 5 9
15 9 23 0 8 6 28 14 45 8 18 24 57 37 87 69 164 208 415 340 611 218 325 591
686 951 922 48 31 90 57 94 57 4 0 15 6 23 14 20 17 192 106 206 106 6 0 -21
-19 -59 -43z m-1125 -1084 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13
-6 -23z m6309 -2031 c0 -176 -37 -494 -75 -652 -33 -135 -88 -316 -115 -375
-5 -11 -25 -60 -45 -108 -106 -262 -275 -548 -479 -812 -99 -128 -368 -408
-476 -497 -142 -116 -179 -145 -276 -214 -57 -41 -106 -74 -109 -74 -3 0 -22
-11 -43 -23 -110 -70 -180 -96 -96 -37 20 14 55 39 78 56 24 18 53 37 65 43
20 11 203 154 251 197 14 12 59 50 100 85 164 138 435 433 550 599 13 19 29
40 35 46 10 12 45 68 135 214 133 218 295 596 356 835 53 208 70 289 88 410
27 188 36 271 36 338 0 38 4 67 10 67 6 0 10 -39 10 -98z m-350 -1478 c0 -8
-5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m-5355 -992 c-3 -3 -11 0
-18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m1052 -698 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M5602 6471 c-8 -4 -12 -19 -10 -32 4 -28 30 -37 114 -38 76 -1 147
-22 252 -75 192 -95 343 -280 378 -465 16 -86 42 -124 68 -101 20 16 21 96 2
165 -17 64 -76 191 -114 247 -97 141 -274 258 -442 293 -81 16 -227 20 -248 6z"/>
<path d="M3925 6301 l-1650 -6 -68 -24 c-135 -46 -268 -155 -336 -274 -68
-120 -64 1 -65 -1882 -1 -1627 0 -1703 18 -1770 10 -38 29 -91 42 -117 58
-115 196 -235 320 -279 132 -46 236 -49 1808 -49 1826 -1 1794 -3 1958 120 88
67 156 143 185 209 57 128 54 40 62 1891 6 1432 5 1714 -7 1760 -42 167 -200
336 -375 402 -35 13 -83 20 -147 22 -52 1 -837 0 -1745 -3z m645 -105 c683 -2
1062 -7 1115 -14 107 -15 191 -58 269 -136 68 -68 111 -146 127 -227 7 -35 12
-407 15 -1049 8 -1897 7 -2341 -6 -2400 -19 -86 -75 -183 -141 -244 -79 -73
-145 -104 -250 -116 -118 -14 -3353 -13 -3439 1 -158 25 -316 178 -349 336 -9
44 -11 477 -9 1794 l3 1735 34 66 c59 117 184 223 297 252 30 8 237 10 671 9
346 -2 1094 -5 1663 -7z"/>
<path d="M5616 6025 c-3 -8 -1 -26 3 -39 7 -18 21 -25 65 -34 56 -11 135 -57
168 -97 9 -11 29 -48 44 -82 21 -52 30 -63 50 -63 38 0 47 31 29 101 -35 137
-155 228 -301 229 -37 0 -54 -4 -58 -15z"/>
<path d="M5629 5787 c-27 -18 -35 -30 -37 -61 -3 -31 2 -44 24 -67 34 -36 63
-37 103 -3 39 32 42 81 9 118 -34 36 -59 40 -99 13z"/>
<path d="M4120 5329 c-25 -5 -70 -14 -100 -20 -30 -6 -118 -31 -195 -57 -156
-51 -253 -107 -365 -210 -57 -52 -63 -61 -63 -98 0 -50 24 -101 74 -158 41
-47 210 -186 304 -251 l56 -38 47 45 c56 54 155 110 235 133 70 20 232 23 308
5 83 -19 166 -65 257 -141 79 -66 85 -69 135 -69 59 0 140 37 267 122 135 91
171 142 156 221 -20 105 -229 297 -436 402 -195 98 -502 149 -680 114z"/>
<path d="M3055 4361 c-3 -10 -10 -86 -14 -167 -10 -155 -8 -163 31 -164 44 0
134 -48 253 -135 136 -100 147 -114 159 -195 l7 -46 -48 -19 c-95 -39 -183
-150 -183 -233 0 -83 89 -223 165 -258 17 -8 37 -18 45 -23 8 -5 33 -14 54
-21 21 -7 65 -29 96 -49 96 -62 160 -88 295 -120 210 -49 256 -54 410 -48 304
13 554 123 786 344 107 102 133 147 127 218 -6 73 -37 111 -148 179 -49 31
-92 56 -95 56 -3 0 -22 10 -43 23 -51 32 -124 51 -166 43 -20 -4 -65 -30 -103
-60 -136 -106 -252 -152 -391 -157 -53 -2 -87 14 -75 35 4 6 -1 5 -10 -3 -18
-14 -72 -7 -62 9 8 14 -15 24 -28 11 -17 -17 -33 -14 -115 25 -59 28 -98 58
-181 137 -65 63 -172 149 -276 222 -268 189 -383 287 -448 382 -25 38 -33 40
-42 14z"/>
<path d="M4170 3620 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M863 5335 c-3 -9 -3 -18 -1 -21 3 -3 8 4 11 16 6 23 -1 27 -10 5z"/>
<path d="M7150 5281 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M7226 3136 c-10 -37 -16 -69 -14 -71 7 -7 41 112 36 126 -2 7 -12
-18 -22 -55z"/>
<path d="M861 2864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M877 2819 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M927 2700 c3 -11 9 -22 13 -25 5 -3 6 4 3 15 -3 11 -9 22 -13 25 -5
3 -6 -4 -3 -15z"/>
<path d="M966 2620 c7 -27 44 -91 43 -75 0 6 -11 30 -25 55 -14 25 -22 34 -18
20z"/>
<path d="M1010 2520 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3250 1535 c-11 -28 -14 -193 -4 -219 5 -12 21 -16 75 -16 62 0 71 3
94 28 14 15 28 35 31 45 6 18 24 24 24 8 0 -5 15 -26 34 -45 30 -32 39 -36 84
-36 60 0 111 33 99 65 -7 18 -16 17 -73 -6 -43 -17 -80 6 -90 57 -6 30 -2 40
22 65 22 23 35 29 54 25 63 -12 65 -12 65 13 0 29 -40 45 -94 37 -36 -5 -101
-59 -101 -83 0 -23 -26 -25 -61 -4 -19 12 -50 21 -69 21 -32 0 -37 4 -45 32
-13 41 -32 47 -45 13z m134 -127 c17 -28 -4 -53 -45 -53 -28 0 -35 4 -37 23
-2 13 -1 30 2 38 8 21 66 14 80 -8z"/>
<path d="M3735 1549 c-9 -14 -9 -224 0 -239 11 -18 32 -11 39 13 3 12 6 39 6
59 0 44 27 66 59 49 24 -13 31 -32 31 -88 0 -40 2 -44 23 -41 20 3 22 9 25 64
4 80 -18 111 -87 120 -44 6 -50 10 -58 37 -9 33 -27 45 -38 26z"/>
<path d="M2836 1528 c-35 -50 -6 -108 62 -123 23 -5 32 -12 32 -26 0 -26 -23
-34 -60 -20 -25 8 -32 7 -37 -4 -11 -29 17 -45 78 -45 52 0 60 3 78 29 40 55
21 86 -73 121 -59 22 -54 44 12 48 35 2 52 7 52 16 0 19 -23 26 -80 26 -39 0
-52 -4 -64 -22z"/>
<path d="M2715 1493 c-47 -65 -99 -163 -86 -163 6 0 11 4 11 9 0 4 17 30 38
57 51 64 97 144 83 144 -6 0 -26 -21 -46 -47z"/>
<path d="M3954 1519 c-4 -7 -7 -56 -8 -110 -1 -98 3 -109 35 -109 14 0 7 222
-8 227 -6 2 -15 -1 -19 -8z"/>
<path d="M3053 1483 c-9 -3 -13 -28 -13 -72 0 -88 15 -103 105 -109 63 -4 63
-4 68 24 10 53 6 132 -7 150 -8 10 -20 15 -30 11 -12 -5 -16 -20 -16 -66 0
-55 -2 -59 -25 -63 -32 -7 -39 4 -45 71 -5 53 -13 64 -37 54z"/>
<path d="M4193 1483 c-47 -9 -78 -84 -57 -138 14 -36 106 -59 132 -33 23 23
12 36 -35 44 -34 7 -49 14 -51 27 -6 28 17 47 55 47 34 0 55 22 37 40 -12 12
-54 19 -81 13z"/>
<path d="M4350 1462 c-39 -40 -41 -88 -4 -131 20 -25 34 -31 64 -31 54 0 87
30 96 86 6 41 4 49 -18 71 -15 15 -41 27 -66 30 -36 5 -45 2 -72 -25z m100
-52 c17 -33 3 -62 -28 -58 -28 3 -54 41 -45 64 8 22 61 17 73 -6z"/>
<path d="M4537 1465 c-11 -27 5 -157 20 -162 22 -8 31 7 34 57 3 60 8 70 29
70 22 0 30 -19 30 -76 0 -31 5 -46 15 -50 29 -11 45 16 45 73 0 51 2 54 23 51
19 -3 23 -10 27 -63 4 -52 8 -60 25 -60 18 0 20 8 23 67 4 98 -4 104 -150 106
-92 1 -117 -1 -121 -13z"/>
<path d="M4941 1461 c-6 -11 -8 -47 -4 -85 5 -55 9 -66 24 -66 15 0 19 8 19
34 0 56 16 88 43 84 19 -3 24 -12 32 -62 6 -33 16 -61 22 -63 37 -13 44 137 8
163 -11 8 -44 14 -77 14 -46 0 -59 -4 -67 -19z"/>
<path d="M5161 1461 c-33 -33 -29 -95 9 -132 25 -26 35 -29 64 -24 19 4 36 2
40 -4 9 -14 -27 -31 -68 -31 -26 0 -36 -4 -36 -15 0 -13 11 -15 57 -13 52 3
59 6 76 35 24 41 26 159 3 181 -8 9 -39 17 -69 20 -46 4 -58 1 -76 -17z m103
-40 c20 -22 14 -56 -10 -65 -19 -7 -54 10 -55 27 -5 53 32 75 65 38z"/>
<path d="M4042 1328 c2 -15 10 -23 23 -23 30 0 27 39 -3 43 -20 3 -23 0 -20
-20z"/>
<path d="M4854 1335 c-4 -9 -2 -21 4 -27 15 -15 44 -1 40 19 -4 23 -36 29 -44
8z"/>
<path d="M3200 7570 c-12 -8 -12 -10 5 -10 11 0 27 5 35 10 12 8 12 10 -5 10
-11 0 -27 -5 -35 -10z"/>
<path d="M3126 7551 c-3 -4 6 -6 19 -3 14 2 25 6 25 8 0 8 -39 4 -44 -5z"/>
<path d="M3068 7533 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4508 573 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4363 563 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M4023 543 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
